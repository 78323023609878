@import 'src/stories/assets/styles/tokens';

.gaas-shopCheckout {
  display: flex;
  flex-flow: column nowrap;
  row-gap: $spacing-m;

  &--list {
    padding-left: $spacing-m;
    &--item {
      padding-left: $spacing-m;
      line-height: $spacing-2xl;
      border-bottom: $border;

      &--quantity {
        display: inline-block;
        width: $spacing-l;
        font-weight: $semibold;
      }
    }
  }
}
