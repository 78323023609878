@import 'src/stories/assets/styles/tokens';

.gaas-shopContract {
  height: $spacing-4xl;
  display: flex;
  align-items: center;

  &--loading {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    row-gap: $spacing-s;
    font-weight: $light;
  }
}
