@import './assets/styles/tokens';

.gaas-shippingAddress {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: $spacing-m;
  background-color: $neutral--grey-50;
  padding: $spacing-xs $spacing-m;

  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $spacing-4xl;

    @include break390 {
      width: auto;
      padding: 0 $spacing-xs;
    }
  }

  &--address {
    display: flex;
    flex-flow: column nowrap;
    span {
      font-weight: $light;
      &:first-of-type {
        font-weight: $semibold;
      }
    }
  }
}
