@import 'src/stories/assets/styles/tokens';

.gaas-shopFree {
  min-height: 100%;
  max-width: 800px;
  padding: $spacing-xl $spacing-m;
  background-color: $contrast--white;

  @include break768 {
    margin: 0 10%;
  }

  @include break768 {
    padding: $spacing-4xl $spacing-l;
  }

  @include break1440 {
    padding: $spacing-4xl $spacing-xl;
  }

  &--background {
    height: 100%;
    background-color: $contrast--white;
  }

  p {
    margin: $spacing-l 0;
  }

  &--customerNo {
    color: $brand--blue-400;
    font-weight: $semibold;
  }

  a {
  }

  &--buttons {
    display: flex;
    flex-flow: column-reverse nowrap;
    row-gap: $spacing-m;
    margin-top: $spacing-xl;

    @include break768 {
      flex-flow: row nowrap;
      column-gap: $spacing-m;
      justify-content: space-between;
    }
  }
}
