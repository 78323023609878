@import './assets/styles/tokens';

.gaas-radio {
  &--horizontal {
    display: flex;
    flex-flow: row nowrap;
    column-gap: $spacing-xs;
    row-gap: $spacing-xs;
  }

  &--vertical {
    flex-flow: column nowrap;
  }

  &--option {
    display: flex;
    flex-flow: row nowrap;
    column-gap: $spacing-2xs;

    &--input {
      height: $spacing-m;
      width: $spacing-m;
      align-items: center;

      input {
        appearance: none;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        border: 1px solid $neutral--grey-400;
        transition: 0.2s all ease;
        cursor: pointer;

        &:hover {
          background-color: $brand--darkblue-300;
        }
        &:active {
          background-color: $brand--darkblue-600;
        }
        &:disabled {
          border-color: $neutral--grey-300;
          pointer-events: none;
        }

        &:checked {
          border: 5px solid $brand--darkblue-400;

          &:hover {
            background-color: $contrast--white;
            border-color: $brand--darkblue-500;
          }
          &:active {
            border-color: $brand--darkblue-600;
          }
          &:disabled {
            border-color: $brand--darkblue-300;
          }
        }
      }
    }

    &--s {
      .gaas-radio--option {
        &--input {
          height: 14px;
          width: 14px;

          input:checked {
            border-width: 4px;
          }
        }

        &--label {
          font-size: $text-s;
          line-height: initial;
        }
      }
    }

    &--l {
      .gaas-radio--option {
        &--input {
          height: 20px;
          width: 20px;

          input:checked {
            border-width: 6px;
          }
        }

        &--label {
          font-size: $text-l;
          line-height: 20px;
        }
      }
    }

    &--label {
      font-weight: $light;
      line-height: $spacing-m;
      cursor: pointer;

      &--disabled {
        pointer-events: none;
      }
    }
  }
}
