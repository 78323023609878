@import './assets/styles/tokens';

.gaas-progress {
  display: flex;
  flex-flow: row nowrap;
  column-gap: $spacing-xs;

  @include break768 {
    column-gap: $spacing-m;
  }

  &-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    column-gap: $spacing-3xs;

    @include break768 {
      column-gap: $spacing-2xs;
    }

    &--active {
      .gaas-progress-item--icon {
        background-color: $neutral--grey-700;
      }
      .gaas-progress-item--label {
        font-weight: $semibold;
      }
    }

    &--done {
      .gaas-progress-item--icon {
        background-color: $brand--blue-500;

        svg path {
          fill: $contrast--white;
        }
      }
    }

    &--icon {
      height: $spacing-m;
      width: $spacing-m;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $neutral--grey-300;

      @include break768 {
        height: $spacing-l;
        width: $spacing-l;
      }

      &--number {
        color: $contrast--white;
        font-size: $display-3xs;

        @include break768 {
          font-size: $display-xs;
        }
      }
    }

    &--label {
      white-space: nowrap;
      font-size: $text-s;
      font-weight: $light;

      @include break390 {
        font-size: $text-xs;
      }
    }
  }
}
