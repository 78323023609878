@import 'src/stories/assets/styles/tokens';

.gaas-shopItemSelect {
  display: flex;
  flex-flow: column nowrap;
  row-gap: $spacing-m;

  &--row {
    @include break768 {
      display: flex;
      justify-content: space-between;
    }
  }

  &--input {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    row-gap: $spacing-m;

    &--withPrice {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $spacing-m;
      align-items: end;

      @include break768 {
        display: initial;
      }
    }

    @include break768 {
      display: grid;
      grid-template-columns: $spacing-4xl 250px auto;
      column-gap: $spacing-m;
      align-items: flex-end;
    }
    &--label {
      margin-bottom: $spacing-xs;
      font-weight: $light;
      color: $neutral--grey-500;
      white-space: nowrap;
    }
    &--button {
      @include break768 {
        align-self: flex-end;
        margin-bottom: $spacing-xs;
        margin-right: $spacing-m;
      }
    }
  }

  &--address {
    display: flex;
    flex-flow: column nowrap;
    // align-items: center;
    justify-content: space-between;
    background-color: $neutral--grey-50;
    padding: $spacing-xs $spacing-m;

    @include break768 {
      flex-flow: row nowrap;
    }

    &--left {
      display: flex;
      align-items: center;
      column-gap: $spacing-m;
      margin-bottom: $spacing-s;

      @include break768 {
        margin-bottom: 0;
      }

      &--address {
        display: flex;
        flex-flow: column nowrap;
        span {
          font-weight: $light;
          &:first-of-type {
            font-weight: $semibold;
          }
        }
      }
    }

    &--right {
      display: flex;
      align-items: center;
      padding-left: $spacing-2xl;
      @include break768 {
        justify-content: initial;
      }
    }
  }
}
