@import './assets/styles/tokens';

.gaas-select {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;

  &--label {
    display: flex;
    align-items: center;

    svg {
      height: 18px;
      width: 18px;
    }
  }

  &--disabled {
    span[class*='--label'] {
      color: $neutral--grey-300 !important;
    }
    input {
      color: $neutral--grey-300;
      border-color: $neutral--grey-300;
      &:hover {
        border-color: $neutral--grey-300;
      }
    }
  }

  &--input {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
    margin-top: $spacing-2xs;

    input {
      cursor: pointer;
      appearance: none;
      height: $spacing-2xl;
      padding-left: $spacing-xs;
      padding-right: $spacing-xs;
      border: 1px solid $neutral--grey-400;
      border-radius: 0;
      background-color: transparent;
      font-size: $text-m;
      font-weight: $light;
      text-overflow: ellipsis;

      &:hover {
        border-color: $contrast--black;
      }

      transition: $standard-transition;
    }

    &--select {
      input {
        caret-color: transparent;
      }
    }

    &--error {
      input {
        border-color: $error--400;
        padding-right: 40px;
      }
    }

    &--icon,
    &--unit {
      position: absolute;
      top: 8px;
    }

    &--icon {
      cursor: pointer;
      right: $spacing-xs;
    }

    &--hint {
      color: $error--500;
      margin: 0;
      margin-top: $spacing-2xs;
    }

    &--unit {
      position: absolute;
      right: calc($spacing-l + $spacing-xs);
      line-height: 24px;
      font-weight: $semibold;
      color: $neutral--grey-400;
      text-align: end;
    }

    &--options {
      position: absolute;
      top: calc($spacing-2xl - 1px);
      width: calc(100%);
      display: flex;
      flex-flow: column nowrap;
      border: 1px solid $neutral--grey-400;
      border-radius: 0;
      background-color: $contrast--white;

      &--withScroll {
        overflow: scroll;
      }

      &--option {
        position: relative;
        min-height: $spacing-xl;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        padding-left: $spacing-xs;
        padding-right: $spacing-xs;
        cursor: pointer;
        transition: $standard-transition;

        span {
          display: block;
        }

        &--title {
          font-weight: $light;
          max-height: 1lh;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &--withContent {
          min-height: $spacing-3xl;
          padding: $spacing-2xs $spacing-xs;
        }

        &--content {
          font-weight: $light;
          color: $neutral--grey-400;
          max-height: 1lh;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &--icon {
          position: absolute;
          right: $spacing-xs;
        }

        &:hover {
          span:first-of-type {
            color: $brand--blue-400;
          }
        }

        &--selected {
          background-color: $neutral--grey-50;
          .gaas-select--input--options--option--title {
            color: $brand--blue-400;
          }
          .gaas-select--input--options--option--icon * {
            fill: $brand--blue-400;
          }

          &:hover {
            color: $brand--blue-500;
          }
        }

        &--disabled {
          cursor: default;
          span {
            color: $neutral--grey-300;
          }
          &:hover {
            span {
              color: $neutral--grey-300 !important;
            }
          }
        }
      }
    }
  }

  &--s {
    input {
      height: $spacing-l;
    }
    .gaas-select--input--icon,
    .gaas-select--input--unit {
      top: 0px;
    }
    .gaas-select--input--options {
      top: calc($spacing-l - 1px);
    }
  }

  &--l {
    input {
      height: $spacing-3xl;
    }
    .gaas-select--input--icon,
    .gaas-select--input--unit {
      top: 14px;
    }
    .gaas-select--input--options {
      top: calc($spacing-3xl - 1px);
    }
  }
}
