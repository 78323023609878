@import 'src/stories/assets/styles/tokens';

.gaas-shopPage {
  min-height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  row-gap: $spacing-l;
  padding: $spacing-xl $spacing-m;

  @include break768 {
    padding: $spacing-4xl $spacing-l;
  }

  @include break1440 {
    padding: $spacing-4xl $spacing-xl;
  }

  &--loading {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    &--icon {
      width: $spacing-5xl;
      margin-top: $spacing-l;
    }
  }

  &--background {
    height: 100%;
    overflow: scroll;
    background-color: $contrast--white;
  }

  &--content {
    display: flex;
    flex-flow: column nowrap;
    row-gap: $spacing-l;

    @include break1024 {
      display: grid;
      grid-template-columns: 60% 40%;
      column-gap: $spacing-l;

      &--checkout {
        grid-template-columns: 50% 50%;
      }
    }

    &--selection {
      display: flex;
      flex-flow: column nowrap;
      row-gap: $spacing-l;

      &--input {
        display: grid;
        grid-template-columns: $spacing-5xl 1fr;
        column-gap: $spacing-m;
        align-items: flex-end;

        &--label {
          margin-bottom: $spacing-xs;
          font-weight: $light;
          color: $neutral--grey-500;
        }
      }
      &--user {
        display: flex;
        flex-flow: column nowrap;
        row-gap: $spacing-m;

        @include break768 {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: $spacing-m;
        }
      }
    }

    &--buttonRow {
      display: flex;
      flex-flow: column-reverse nowrap;
      row-gap: $spacing-m;

      @include break1024 {
        flex-flow: row nowrap;
        justify-content: space-between;
      }
    }
  }
}
