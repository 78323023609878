@import './assets/styles/tokens';

.gaas-shop-item {
  display: flex;
  flex-flow: column nowrap;
  row-gap: $spacing-m;

  p {
    margin: 0;
  }

  &--details {
    display: flex;
    flex-flow: column nowrap;
    row-gap: $spacing-m;

    @include break1440 {
      display: flex;
      flex-flow: row nowrap;
      column-gap: $spacing-l;
    }

    &--img {
      min-width: 60%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        min-height: 100%;
        min-width: 100%;
      }
    }

    &--desc {
      display: flex;
      flex-flow: column nowrap;
      row-gap: $spacing-m;
      ul {
        margin: 0;
        padding-left: $spacing-m;

        h4 {
          margin-bottom: 0;
          margin-left: -$spacing-m;

          &:first-of-type {
            margin-top: 0;
          }
        }
        li {
          line-height: $spacing-l;
          font-weight: $light;
        }
      }
    }
  }
}
