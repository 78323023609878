@import './assets/styles/tokens';

.gaas-shopSummary {
  position: sticky;
  top: 16px;
  height: fit-content;

  background-color: $neutral--grey-50;
  margin-left: -$spacing-m;
  margin-right: -$spacing-m;
  padding: $spacing-m;
  overflow: hidden;

  @include break768 {
  }

  @include break1024 {
    margin: 0;
  }

  h4 {
    margin: 0;
    margin-top: $spacing-m;
  }

  &--specialTitle {
    span {
      font-weight: $light;
    }
  }

  &--item {
    display: flex;
    justify-content: space-between;
  }

  &--total {
    font-size: $text-xl;
    font-weight: $semibold;
  }

  &--checkout {
    display: flex;
    flex-flow: column nowrap;
    row-gap: $spacing-m;
  }
}
