@import '../../../stories/assets/styles/tokens';

.gaas-shopConfirm {
  min-height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: $spacing-xl $spacing-m;

  background-color: $contrast--white;

  @include break768 {
    padding: $spacing-4xl $spacing-l;
  }

  @include break1440 {
    padding: $spacing-4xl $spacing-xl;
  }

  &--background {
    height: 100%;
    background-color: $contrast--white;
  }

  p {
    max-width: 640px;
    margin: $spacing-l 0;
  }
}
